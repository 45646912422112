import {Text, View} from "../components";

const ScreenTimeline = () => {
    return(
        <View style={{ height: '100%', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'black', fontSize: 32 }}>
                Hết ròi, có nhiêu đó hoi
            </Text>
        </View>
    )
};

export default ScreenTimeline;
